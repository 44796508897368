import React, { useState, useEffect, useRef } from "react";
import { getDatabase, ref, get } from "firebase/database";
import firebase from "../../../../firebase/firebase";
import { updateChatNotifty, removeWhatsAppNotifications, readWhatsAppNotifications } from "../../../../services/cn/Service";
import { useNavigate,Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { useUserTabs } from "../common/UserTabsContext";
import axios from "axios";

const pageSize = 5; // Number of notifications per page

const NotificationDropdown = () => {
  const cnchatnode = useSelector((state) => state.webex.cnchatnode);
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [visibleNotifications, setVisibleNotifications] = useState(pageSize);
  const containerRef = useRef(null);
  const { addUserTab } = useUserTabs();

  useEffect(() => {
    if (isOpen) {
      fetchNotifications();
    }
  }, [isOpen]);

  useEffect(() => {
    fetchNotifications();
  }, [cnchatnode]);

  const handleUserClick = (user) => {
    addUserTab(user);
};

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const parseTime = (timeString) => {
    return new Date(timeString).getTime(); // Convert "YYYY-MM-DD HH:mm:ss" → UNIX timestamp
  };

  const fetchNotifications = async () => {
    try {
      if (!cnchatnode) return;

      const database = getDatabase(firebase);
      const notificationsRef = ref(database, cnchatnode);
      const snapshot = await get(notificationsRef);

      if (snapshot.exists()) {
        const data = snapshot.val();
        console.log("firebasedata", data);
        
        const fetchedNotifications = Object.keys(data)
          .map((key) => ({
            id: key,
            message: data[key]?.message || "No message",
            read: data[key]?.read || false,
            time: parseTime(data[key]?.time),
            userId: data[key]?.userId,
            name: data[key]?.name,
          }))
          .sort((a, b) => b.time - a.time); // Sort by time in descending order

        setNotifications(fetchedNotifications);
      } else {
        setNotifications([]);
      }
    } catch (error) {
      console.error("Error fetching notifications:", error);
    }
  };

  const formatMessageTime = (messageTime) => {
    const messageDate = new Date(messageTime);
    const now = new Date();
    const timeDiff = now - messageDate;

    const seconds = Math.floor(timeDiff / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    if (seconds < 60) return "Just now";
    if (minutes < 60) return `${minutes} min${minutes > 1 ? "s" : ""} ago`;
    if (hours < 24) return `${hours} hour${hours > 1 ? "s" : ""} ago`;
    if (days === 1) return "Yesterday";

    return messageDate.toLocaleDateString("en-US", {
      day: "numeric",
      month: "short",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });
  };

  const handleNotificationClick = async (index, id, name) => {
    const request = axios.CancelToken.source();
    let ct = {
        cancelToken: request.token
    }
    await readWhatsAppNotifications(id, ct)
        .then((response) => {
          handleUserClick({ userId: id, name: name })
        }).catch(function (error) {
            console.log(error);
        });

        setNotifications((prev) =>
          prev.map((notification, i) =>
            i === index ? { ...notification, read: true } : notification
          )
        );
        setIsOpen(false);
      return () => request.cancel();
    //navigate("/members/" + encodeURIComponent(id, "UTF-8"));
  };

  const removeWhatsAppNotificationAction = async (userId, action) => {
    const request = axios.CancelToken.source();
    let ct = {
        cancelToken: request.token
    }
    await removeWhatsAppNotifications(userId, action, ct)
        .then((response) => {
          fetchNotifications();
        }).catch(function (error) {
            console.log(error);
        });
    return () => request.cancel();
}

  return (
    <div className="tw-relative tw-pe-4 tw-ps-3" ref={containerRef}>
      <button
        type="button"
        onClick={() => setIsOpen(!isOpen)}
        className="btn message-icon position-relative tw-border-none"
      >
        <i className="bi bi-whatsapp tw-pb-6"></i>
        {notifications.length > 0 && (
          <span className="tw-absolute tw-top-0 tw-start-[60%] tw-bg-amber-400 tw-animate-bounce tw-rounded-full tw-text-white tw-text-xs tw-px-1 border tw-border-white">
            {notifications.length}
          </span>
        )}
      </button>

      {isOpen && (
        <div className="tw-relative">
          <ul className="tw-absolute tw-z-40 tw-top-[-8px] tw-right-0 tw-mt-2 tw-bg-white border tw-rounded-lg tw-shadow-2xl tw-p-0 tw-w-80 tw-max-h-[25rem] tw-overflow-y-auto tw-overflow-x-hidden [&::-webkit-scrollbar]:tw-hidden [-ms-overflow-style:none] [scrollbar-width:none]">
            {notifications.slice(0, visibleNotifications).map((data, index) => {
              const parts = data.message.split(" has ");
              const name = parts[0];
              const restOfMessage = parts[1];

              return (
                <li
                  key={index}
                  className={`tw-relative tw-px-4 tw-py-2 tw-cursor-pointer hover:tw-bg-gray-50  tw-transition-all tw-duration-500 tw-list-none tw-min-h-[4rem] border-bottom tw-border-gray-300 ${
                    !data.read ? "tw-bg-[#f1f6fc]" : "tw-bg-white"
                  }`}
                >
                  <div className="tw-flex tw-items-center">
                    <i className="bi bi-whatsapp tw-text-2xl tw-text-gray-400 tw-pr-5"></i>
                    <div className="tw-pr-16">
                      <span className="tw-text-gray-700 tw-text-sm">
                        <Link to={'/members/' + encodeURIComponent(data.userId, "UTF-8")}
                          className="tw-font-semibold tw-cursor-pointer tw-text-[#0d638f] tw-text-base"
                          onClick={() => handleNotificationClick(index, data.userId, data.name)}
                        >
                          {name}
                        </Link>{" "}
                        has {restOfMessage}.
                      </span>
                    </div>
                    <span className="tw-absolute tw-bottom-3 tw-right-4 tw-text-gray-400 tw-text-xs">
                      {formatMessageTime(data.time)}
                    </span>
                    <span
                      className="tw-absolute tw-top-2.5 tw-right-4 tw-cursor-pointer tw-text-gray-400 tw-text-sm"
                      onClick={() => removeWhatsAppNotificationAction(data.userId, "read")}
                    >
                      <i className="bi bi-trash tw-text-[#0d638f]"></i>
                    </span>
                  </div>
                </li>
              );
            })}

            {notifications.length > visibleNotifications && (
              <li
                className="tw-text-center tw-text-blue-500 tw-cursor-pointer tw-py-2 tw-border-t"
                onClick={() => setVisibleNotifications((prev) => prev + pageSize)}
              >
                Load More
              </li>
            )}

            {notifications.length === 0 && <li className="tw-text-center tw-py-3">No notifications</li>}
          </ul>
        </div>
      )}
    </div>
  );
};

export default NotificationDropdown;
