import React, { useState } from 'react';
import DataTable from 'react-data-table-component';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import {deleteCheckTemplate, deleteTemplate, viewTemplateHistory } from '../../../services/mtp/Service.js';
import SubmitModal from './SubmitModal.js';
import { Link } from 'react-router-dom';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import { MultiSelect } from 'react-multi-select-component';
import LogModal from './LogModal.jsx';

const StatusBadge = ({ status }) => {
  const badgeStyle = {
    padding: '4px 12px',
    borderRadius: '12px',
    fontSize: '12px',
    fontWeight: '500',
    textTransform: 'capitalize',
  };

  const approvedStyle = {
    ...badgeStyle,
    // backgroundColor: '#E8F5E9',
    color: '#4CAF50',
  };

  const draftStyle = {
    ...badgeStyle,
    // backgroundColor: '#FAA0A0',
    color: '#FF3131',
  };

  const pendingStyle = {
    ...badgeStyle,
    // backgroundColor: '#FFECB3',
    color: '#FFC107',
  }

  return (
    <span style={status === "Pending Review" ? pendingStyle : approvedStyle}>
      {status}
    </span>
  );
};

const WAPendingRequest = ({templates,getTemplates,lastSync,createAction}) => {
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedCategory, setSelectedCategory] = useState('All');
    const [selectedStatus, setSelectedStatus] = useState('All');
    const [selectedLanguage, setSelectedLanguage] = useState('All');
    const [optionTags, setOptionTags] = useState([]);
    const [selectedTags, setSelectedTags] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [templateToDelete, setTemplateToDelete] = useState(null);
    const [submitModal, setSubmitModal] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [viewHistoryData, setViewHistoryData] = useState([]);
    const [viewHistoryApprovedData, setViewHistoryApprovedData] = useState([]);
    
    

    const handleInputTags = (selectedOptions) => {
        setSelectedTags(selectedOptions);
      };
  
  
    const handleShow = async (templateId) => {
      setTemplateToDelete(templateId); // Store the templateId when modal is shown
      let resp = await deleteCheckTemplate(templateId);
      if(resp.data.code === 200){
        setShowErrorModal(true);
        setError(resp.data.data.message);
      }else{
        setShowModal(true);
      }
    };
    const handleClose = () =>{
      setShowModal(false);
      setShowErrorModal(false);
    } 
  
    const onDeleteTemplate = async (templateId) => {
      try {
        const response = await deleteTemplate(templateId);
        setError("");
        if(response.data.code === 200){
          window.location.reload();
          setShowModal(false);
          
        }else{
          setShowErrorModal(false);
          setError(response.data.message);
          setShowModal(false);
        }
      } catch (err) {
        setError('Error deleting template. Please try again.');
      } finally {
        setLoading(false);
      }
    };

    const handleViewHistory = async (templateId) => {
      try {
            let resp = await viewTemplateHistory(templateId);
            setError("");
            console.log("resp.data.approvedData111",resp.data.data);
            setLoading(true);
            if(resp.data.code === 200){
                setViewHistoryData(resp.data.data);
                
                
                setViewHistoryApprovedData(resp.data.data);
                setIsOpen(true);
            }else{
              setError(resp.data.message);
            }

          } catch (err) {
            setError('Error history template. Please try again.');
          } finally {
            setLoading(false);
          }
      };
  
    const columns = [
      { name: 'Template Name', selector: (row) => row.templateName, sortable: true },
      { name: 'Status', selector: (row) => row.status, sortable: true, cell: (row) => <StatusBadge status={row.status} /> },
      { name: 'Category', selector: (row) => row.category, sortable: true },
     // { name: 'Language', selector: (row) => row.language, sortable: true },
      { name: 'Created By', selector: (row) => row.createdBy, sortable: true },
      { name: 'Date Submitted for Review', selector: (row) => row.createdTime, sortable: true },
    // { name: 'Uploader Comment', selector: (row) => "Dummy Comment", sortable: false },
    { name: 'Uploader Comment', selector: (row) => row.uploaderComment, sortable: true },
    { name: 'Approver Comment', selector: (row) => row.comments, sortable: true },
    { name: 'Assignee Role & Name', selector: (row) => row.assigneeName, sortable: true },
    
    {
        name: 'Action',
        cell: (row) => (
            <>
            <div className="d-flex align-items-center">
                <OverlayTrigger placement="top" overlay={<Tooltip>View</Tooltip>}>
                <Link to={`/review-template/${row.templateId}`}>
                  <span className="tw-px-2">
                    <i className="bi bi-eye tw-text-black hover:tw-text-[#215a87]"></i>
                  </span>
                </Link>

                </OverlayTrigger>
              </div>

          {createAction && (
            row.isEditOption && (
                <>
                <span className="">|</span>
                <div className="d-flex align-items-center">
                    <OverlayTrigger placement="top" overlay={<Tooltip>Edit</Tooltip>}>
                    <Link to={`/edit-template/${row.templateId}`}>
                      <span className="tw-px-2">
                      <i className="bi bi-pencil tw-text-black hover:tw-text-[#215a87]"></i>
                      </span>
                  </Link>
                  </OverlayTrigger>
                </div>
                </>
            )

          )}

              <div className="d-flex align-items-center">
              <span className="">|</span>
                <OverlayTrigger placement="top" overlay={<Tooltip>Template History</Tooltip>}>
                {/* onClick={() => setIsOpen(true)} */}
                  <span onClick={() => handleViewHistory(row.templateId)} className="tw-px-2 tw-cursor-pointer">
                    <i className="bi bi-info-circle tw-text-black hover:tw-text-[#215a87]"></i>
                  </span>
                </OverlayTrigger>
              </div>
          
          </>
        ),
          
      ignoreRowClick: true,
    },
   
    
    ];
  
    const customStyles = {
      headCells: {
        style: {
          backgroundColor: '#D4E6EF',
          fontWeight: '500',
          fontSize: '14px',
          whiteSpace: 'normal',
        },
      },
      rows: {
        style: {
          whiteSpace: 'normal',
        },
      },
      cells: {
        style: {
          whiteSpace: 'normal',
        },
      },
    };
  
    const handleClear = () => {
      setSearchQuery('');
      setSelectedCategory('All');
      setSelectedStatus('All');
      setSelectedLanguage('All');
    };
  
    const filteredData = templates.templateData?.filter((item) => {
      const matchesSearchQuery =  item.templateName.toLowerCase().includes(searchQuery.toLowerCase()) ||
      item.createdBy.toLowerCase().includes(searchQuery.toLowerCase())
      const matchesCategory = selectedCategory === 'All' || item.category.toLowerCase() === selectedCategory.toLowerCase();
      const matchesStatus = selectedStatus === 'All' || item.approvedStatus.toLowerCase() === selectedStatus.toLowerCase();
      const matchesLanguage = selectedLanguage === 'All' || item.language.toLowerCase() === selectedLanguage.toLowerCase();
      return matchesSearchQuery && matchesCategory && matchesStatus && matchesLanguage;
    });
  
    return (
      <>
        <div className="container-fluid">
          <div className="row mb-3 align-items-center">
          
            <div className="col-md d-flex px-1">
              <div className="form-group" style={{ width: '100%' }}>
                <input
                  type="text"
                  placeholder="Search By Template Name or Creator"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  className="form-control input-field-ui ps-5"
                />
                <img
                  src="https://storage.googleapis.com/ksabupatest/2024/09/24/ljce8/fac3csq13o.png"
                  className="input-icon-seacrh"
                  alt=""
                />
              </div>
            </div>
            <div className="col-md-2 px-1">
              <select
                value={selectedStatus}
                onChange={(e) => setSelectedStatus(e.target.value)}
                className="form-select input-field-ui"
              >
                <option value="All">--Select Status--</option>
                <option value="Pending">Pending Review</option>
                <option value="rejected">Reviewed</option>
                {/* <option value="approval pending">Pending</option> */}
              </select>
            </div>
            <div className="col-md-2 px-1">
            <select
              value={selectedCategory}
              onChange={(e) => setSelectedCategory(e.target.value)}
              className="form-select input-field-ui"
            >
              <option value="All">--Select Category--</option>
              <option value="marketing">Marketing</option>
              <option value="utility">Utility</option>
              <option value="authentication">Authentication</option>
            </select>
          </div>
            <div className="col-md-2 px-1">
              <select
                value={selectedLanguage}
                onChange={(e) => setSelectedLanguage(e.target.value)}
                className="form-select input-field-ui"
              >
                <option value="All">--Select Language--</option>
                <option value="english">English</option>
                <option value="arabic">Arabic</option>
              </select>
            </div>
  
            <div className="col-md-auto px-1">
              <button onClick={handleClear} className="btn btn-primary clear-search-btn">
                Clear
              </button>
            </div>
            <div className="col-md-auto pe-1">
              <h6 className="last-sync">Latest sync on</h6>
              <h6 className="last-sync">
                <span>{templates.lastSync || 'Fetching...'}</span>
              </h6>
            </div>
            <div className="col-md-auto px-1">
              <button className="btn btn-outline-dark" onClick={(e) => getTemplates('active3',1)}>
                <i className="bi bi-arrow-clockwise"></i>
              </button>
            </div>
          </div>
        </div>
        <DataTable
          columns={columns}
          data={filteredData}
          pagination
          paginationPerPage={10}
          striped
          responsive
          noHeader
          customStyles={customStyles}
          
        />
        <LogModal 
        isOpen={isOpen}
        viewHistoryData={viewHistoryData || []}
        onClose={() => setIsOpen(false)}
        viewHistoryApprovedData={viewHistoryApprovedData || []}
        />
      </>
    );
  }

export default WAPendingRequest